@import '../home';

@import '~@mshops-web-components/newsletter-modal/styles/explorer/desktop';

@import '~@mshops-components-library/item/src/styles/explorer/desktop';
@import '~@mshops-components-library/item/src/styles/explorer/v2/desktop';
@import '~@mshops-components-library/common/src/Components/Section/styles/desktop';
@import '@mshops-components-library/section-text/src/styles/general/desktop';
@import '~@mshops-components-library/video-and-text/src/styles/general/desktop';
@import '~@mshops-components-library/video-and-text/src/styles/explorer/desktop';
@import '~@mshops-components-library/content-list/src/styles/explorer/desktop';
@import '~@mshops-components-library/slide/src/styles/explorer/desktop';
@import '~@mshops-components-library/products-carousel/src/styles/v2/explorer-v2/desktop';
@import '~@mshops-components-library/products-gallery/src/styles/explorer/desktop';
@import '~@mshops-components-library/categories-gallery/src/styles/templates/explorer/desktop';
@import '~@mshops-components-library/categories-carousel/src/styles/explorer/desktop';
@import '~@mshops-components-library/slider/src/styles/original/desktop';
@import '~@mshops-components-library/tab-with-items/src/styles/explorer/desktop';
@import '~@mshops-components-library/card-action/src/styles/desktop';
@import '~@mshops-components-library/modal-add-to-cart/src/styles/iframe-modal.desktop';

@import '../../../../components/payment-data/desktop';
@import '../../../../components/badge-carousel/styles/desktop';
@import '../../../../components/site-shopping-info/explore/desktop';
@import '../../../../components/message/styles/desktop';
@import '../../../../components/CategoryLabelImage/styles/templates/explorer/desktop';
@import '../../../../appearance/buyerInfo/styles/desktop';

// Contact Page
@import '../../../../components/contact-form/styles/desktop';
@import '../../../../components/contact-info/styles/desktop';

// How Buy Landing
@import '../../../../components/HowBuyInfo/styles/desktop';

// Cancel Buy Landing
@import '../../../../appearance/cancelBuyInfo/styles/desktop';

// Bank Conditions Landing
@import '../../../../appearance/bankConditionsInfo/styles/desktop';

// LGPD Landing
@import '../../../../appearance/dataPrivacyInfo/styles/desktop';

// Legal Notices Landing
@import '../../../../components/LegalNoticesInfo/styles/desktop';

// Adhesion contracts Landing
@import '../../../../appearance/adhesionContractInfo/styles/desktop';

// Not Found Page for CCP
@import '../../../../components/NotFoundCCP/styles/desktop';

// Protected Purchase Landing
@import '../../../../components/ProtectedPurchaseInfo/styles/desktop';

// Splinter desktop
@import '~@mshops-components-library/splinter-grid/styles/explorer/desktop';

.home {
  .row {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .hub_carousel {
    max-width: rem(1250px);

    .carousel-container {
      max-width: 95%;
    }

    .arrow-disabled {
      cursor: default;
    }
  }

  &.home--explorer {
    padding-top: rem($large-header-height-material);

    &.home--with-flash {
      padding-top: rem($large-header-height-material + $flash-component-height);
    }
  }

  &.home--with-banner {
    padding-top: rem($andes-spacing-64);
  }
}

.home--with-banner .payment-data {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: rem($payment-methods-top-with-banner);
  width: 100%;
}

/* Modifiers for flash component and banner components */
.nav-header__banner-image {
  background-size: 100% auto;
  height: rem(200px);
  width: 100%;
}

.wrapper--with-banner + main > .home {
  padding-top: 0;

  &.home--with-payment {
    padding-top: rem($andes-spacing-40);
  }
}

.home--with-flash {
  padding-top: rem($large-header-height + $flash-component-height);
}

/* Minor styles fixes for the site shopping info */
.site-shopping-info .info-slide {
  padding-bottom: rem($andes-spacing-64);
  padding-top: rem($andes-spacing-64);
}

@media (width <= 768px) {
  .nav-header__banner {
    background-color: $light-gray;
    display: block;
    line-height: 0;
    padding-top: rem($small-header-height);
    width: 100%;
  }

  .nav-header__banner-image {
    background-size: 100% auto;
    height: rem(96px);
    width: 100%;
  }

  .home--with-flash {
    padding-top: rem($small-header-height + $flash-component-height);
  }

  .wrapper--with-banner + main > .home {
    padding-top: 0;
  }

  .payment-data {
    position: relative !important;
    top: 0 !important;
  }

  .header-wrapper--fixed + main > .nav-main-content,
  .header-wrapper--fixed + main > .home {
    padding-top: rem(90px);
  }
}

.home.home--explorer
  .component-wrapper
  .mshops-recommendations-wrapper
  .ui-item__wrapper
  .ui-item__content {
  display: flex;
  flex-direction: column;

  .ui-item__title {
    font-size: rem($font-size-16);
    order: 0;
  }

  .ui-item__price-block {
    display: flex;
    flex-direction: column;
    order: 1;

    .price-tag {
      font-size: rem($font-size-20);
      order: 0;
    }

    .ui-item__discount-price {
      font-size: rem($font-size-16);
      order: 1;
    }
  }

  .ui-ms-shipping-container {
    order: 2;
  }
}

.home.home--explorer
  .mshops-recommendations-wrapper
  .carousel-with-tabs
  .ui-item {
  padding: 0;
}

.home.home--explorer .ui-item__wrapper .ui-item__content {
  flex-direction: revert;
}

.home.home--explorer .ui-item__content {
  flex-wrap: nowrap;
}

.recommendations {
  .section-header {
    flex-direction: column;
    width: 100%;

    .carousel-with-tabs__tabs-list {
      margin-bottom: rem($andes-spacing-32);
    }

    h1,
    h2,
    .section-header__title--base,
    h3 {
      color: $andes-black;
      display: block;
      font-size: rem($font-size-32);
      text-align: center;
      width: 100%;
    }

    a {
      margin: 0 auto;
    }

    .tabs-list__item div[role='button'] {
      font-weight: $font-weight-regular;
    }
  }

  .ui-item__title {
    max-height: none;
  }
}

.grid-container .with-discount .ui-item__price-block {
  display: flex;
  flex-direction: column-reverse;
}

div.slick-slider {
  button.prev-button,
  button.next-button {
    background-color: transparent;
  }
}

.home.home--explorer
  .mshops-recommendations-wrapper
  section
  .section-header-products-gallery {
  margin-bottom: 0 !important;
}

.home.home--explorer
  .mshops-recommendations-wrapper
  .carousel-with-tabs
  .carousel-with-tabs__tabs-list {
  box-shadow: 0 6px 16px 0 #0000001a;
}
