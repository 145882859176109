@import '~sass-rem/rem';

@import '~@andes/normalize/index';

@import '~@mshops-components-library/common/src/Components/Button/styles.scss';
@import '~@andes/progress-indicator-circular/index';
@import '~@andes/carousel-snapped/index';
@import '~@andes/modal/index';
@import '~@andes/input-stepper/index';
@import '~@andes/tabs/index';
@import '~@andes/tooltip/index';

@import '../../../styles/mesh';
@import '../../../styles/mixins';
@import '../../../styles/common';
@import '../../../styles/variables';
@import '../../../styles/utilities';
@import '../../../styles/reset';

@import 'variables';

// Common imports
@import '../../../components/newsletter-modal/styles/core';
@import '~@mshops-components-library/rich-text/src/styles/desktop';

// Use defaults box-sizing instead of Andes Normalize

// Import Playfair Display font
@import 'https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap';

*,
*::before,
*::after {
  box-sizing: content-box;
}

html {
  font-size: $font-size-16;
}

body {
  font-family: $font-family-primary;
}

main[role='main'] {
  font-weight: $font-weight-regular;
  -webkit-font-smoothing: antialiased;
}

.home {
  overflow-x: hidden; /* Older browsers */
  overflow-x: clip;
  padding: $home-padding;

  /* flexbox grid */
  .row {
    padding-bottom: 0;
    position: relative;

    h3 {
      margin-bottom: $section-separator;
      margin-top: $section-separator;
    }

    .title {
      color: #9c9c9c;
      font-size: 26px;
      font-weight: 200;
      margin-bottom: 0;
      margin-left: 6px;
      margin-top: 0;
    }

    &.center {
      justify-content: center;
    }
  }

  .component-wrapper {
    display: contents;
  }

  @media screen and (width <= 767px) {
    .ui-modal {
      &-bg {
        background-color: rgb(0 0 0 / 60%);
      }

      &-dialog__button-close {
        &::after,
        &::before {
          background-color: $andes-white;
        }
      }

      &-dialog,
      &-bg {
        display: table;
        opacity: 1;
        transition: opacity $transition-duration $transition-timing-function;

        &__content-wrapper {
          height: 100%;
        }

        &--hidden {
          opacity: 0;
          pointer-events: none;

          iframe {
            display: none;
          }

          .ui-modal-dialog {
            &__content-box,
            &__button-close {
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

.home--with-padding-bottom {
  padding-bottom: 8 * $mdu;
}

.nav-footer-contact {
  margin-top: 1px;
}

// Override tools footer margin needed in vip, search and cart
.nav-tools-footer {
  margin-top: 0 !important;
}

.modal {
  max-height: 88%;
  max-width: rem(840px);
  width: rem(840px);

  iframe {
    border: 0;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }
}

.clipped {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  word-break: break-word;
  z-index: -1;
}

.nav-new-cookie-disclaimer {
  @include z-index(cookie-disclaimer);
}

div.mshops-recommendations-wrapper div.carousel-container {
  max-height: rem(450px);
}
