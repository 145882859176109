.section-category-item {
  height: 100%;
  width: 100%;
}

.section-category-item__image-default {
  background-color: $andes-white;

  .section-category-item__image {
    object-fit: contain !important;
  }
}

.section-category-item__image-custom .section-category-item__image {
  object-fit: cover;
}
